import React from 'react'
import Logo from '../../assets/logo.svg'
import Threadality from '../../assets/threadality.svg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCompany, selectDeviceInfo } from '../../slices/deviceSlice'

function Navbar() {
    const history = useHistory()
    const company = useSelector(selectCompany)
    const deviceInfo = useSelector(selectDeviceInfo)
    return (
        <nav className='bg-[#1F4B4B] py-3 px-5 flex items-center justify-between'>
            <div className='flex items-end cursor-pointer' onClick={() => history.push("/")}>
                <img src={Logo} alt="" />
                <h1 className='text-3xl font-medium'>Aqqess</h1>
            </div>

            {deviceInfo?.sector?.sector ? <div className='max-w-max'>
                <h1 className='text-4xl font-medium'>{deviceInfo?.sector?.sector}</h1>
                <div className='h-1 w-full rounded-full bg-[#0BC3CF]'></div>
            </div> : <></>}
            <div>
                <img src={company?.logo || deviceInfo?.logo || Threadality} className='h-16' alt="" />
            </div>

        </nav>
    )
}

export default Navbar