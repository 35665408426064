import { createRef, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
var mapKey = process.env.REACT_APP_MAP_API_KEY;

export const encryptPassword = (password) => simpleCrypto.encrypt(password)

export const openLink = (link) => {
    if (!link?.substring(0, 4)?.includes('http')) {
        link = 'https://' + link
    }
    return window.open(link, '_blank')
}

export const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export const fetchPlace = async (text) => {
    try {
        // country, region, place, district, locality, postcode, neighborhood, address, poi, poi.landmark
        const res = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${mapKey}&cachebuster=1625641871908&autocomplete=true&types=place,region,country`
        );
        if (!res.ok) throw new Error(res.statusText);

        return res.json();
    } catch (err) {
        return { error: "Unable to retrieve places" };
    }
};

export const useClickOutside = (handler, ref = null) => {
    const domRef = ref || createRef(null);

    useEffect(() => {
        const localHandler = (e) => {
            if (!domRef.current) return;
            if (!domRef.current.contains(e.target)) handler();
        };
        document.addEventListener("mousedown", localHandler);
        return () => document.removeEventListener("mousedown", localHandler);
    }, [domRef, handler]);

    return domRef;
};


export default function useDebounce(value, delay = 300) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay);
        return () => {
            clearTimeout(handler);
        }
    }, [value, delay]);

    return debouncedValue;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export const uploadImage = async (image) => {
    let data = new FormData();
    // let tId = toast.loading("Uploading image...");
    data.append("file", image)
    data.append("upload_preset", `greyffiti`)
    data.append("cloud_name", `greyffiti`)
    const resp = await fetch(`https://api.cloudinary.com/v1_1/greyffiti/image/upload`, {
        method: "post",
        body: data
    })
    let res = await resp.json();
    // toast.dismiss(tId);
    if (res.secure_url)
        console.log(`File "${image?.name}" Uploaded successfully`, { id: 'success' })
    else
        console.log(`Unable to Upload File "${image?.name}"`, { id: 'error' })
    return res.secure_url
}

export const decodeToken = () => {
    const jwtToken = localStorage.getItem("device_aqqess_token")

    if (!jwtToken) {
        return false;
    }
    var decoded = jwt_decode(jwtToken);
    return decoded?.userInfo
}
