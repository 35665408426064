import { endpoints } from './config';
import HttpService from './axios-interceptor';

const auth = () => {
    let deviceId = localStorage.getItem("device-id")
    return HttpService.post(`/device`, { deviceId });
};
// const auth = (id) => {
//     let deviceId = localStorage.getItem("device-id")
//     return HttpService.get(`/device?deviceId=${deviceId}`);
// };

const getCompany = (subdomain) => {
    return HttpService.get("/admin" + endpoints.company + `?subdomain=${subdomain}`);
};

const resetDevice = () => {
    let deviceId = localStorage.getItem("device-id")
    return HttpService.post("/device" + endpoints.reset, { deviceId });
};

const authorizeSetting = (data) => {
    return HttpService.post("/device/authorizeSetting", data);
};

const validateDeviceName = (name) => {
    return HttpService.get("/device/validate/deviceName" + `?name=${name}`);
};

const updateDevice = data => {
    return HttpService.patch(
        "/device",
        data,
    );
};
const registerDevice = data => {
    return HttpService.post(
        "/device/register",
        data,
    );
};
const updateDeviceState = data => {
    return HttpService.patch(
        "/device/state",
        data,
    );
};

const verifyQR = data => {
    return HttpService.post(
        "/device" + endpoints.verifyQR,
        data,
    );
};

export const DeviceService = {
    updateDevice,
    auth,
    getCompany,
    registerDevice,
    updateDeviceState,
    verifyQR,
    validateDeviceName,
    resetDevice,
    authorizeSetting
};