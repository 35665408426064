import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import ConfirmationModal from '../Reusable/ConfirmationModal'
import { useHistory } from 'react-router-dom'
import { useAuthorizeSetting, useRegisterDevice, useUpdateDevice, useUpdateDeviceState } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompany, selectDeviceInfo, setDeviceInfo } from '../../slices/deviceSlice'
import { decodeToken } from '../../services/constant'
import toast from 'react-hot-toast'
import useDebounce from '../../services/constant'
import { DeviceService } from '../../services/device.service'
import { useQuery } from 'react-query'
import moment from 'moment'
import { QrReader } from 'react-qr-reader'
import { PopUp } from './DeviceHome'


function scrambleWord(word) {
    const wordArray = word.split('');
    for (let i = wordArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [wordArray[i], wordArray[j]] = [wordArray[j], wordArray[i]];
    }
    const scrambledWord = wordArray.filter(el => el?.trim()).join('');
    return scrambledWord;
}
function DeviceSetting() {
    const dispatch = useDispatch()
    const history = useHistory();
    const deviceInfo = useSelector(selectDeviceInfo)
    const company = useSelector(selectCompany)
    const [disabled, setDisabled] = useState(true)
    const [confirmation, setConfirmation] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isSetUp, setIsSetUp] = useState(false);
    const [deviceId, setDeviceId] = useState()
    const [error, setError] = useState(undefined);
    const [data, setData] = useState({
        displayName: '',
        latitude: '',
        longitude: ''
    });
    const [deviceExist, setDeviceExist] = useState('');
    const [reset, setReset] = useState(false);
    const [verified, setVerified] = useState(false);
    const [time, setTime] = useState(moment())
    const [popUp, setPopUp] = useState(false)

    useEffect(() => {
        setInterval(() => {
            setTime(moment())
        }, 1000);
    }, [])

    useEffect(() => {
        const auth = decodeToken()
        if (!auth) {
            setIsSetUp(true)
        }
        // getIp()
    }, [])

    useEffect(() => {
        if (deviceInfo?.displayName) {
            setData({ ...data, displayName: deviceInfo?.displayName });
            setDisabled(!Boolean(deviceInfo?.active))
        }
    }, [deviceInfo])

    useEffect(() => {
        if (success) {
            setTimeout(() => { setSuccess(false); history.push('/') }, 5000)
        }
    }, [success])

    let { refetch: resetDevice } = useQuery('resetDevice', () => DeviceService.resetDevice(), {
        enabled: false,
        onSuccess: res => {
            if (res?.data) {
                localStorage.removeItem('device-id')
                localStorage.removeItem('device_aqqess_token')
                setReset(false);
                window.location.replace('/')
            }
        }
    })

    let { refetch: validateDeviceName } = useQuery('validateDeviceName', () => DeviceService.validateDeviceName(data.displayName), {
        enabled: false,
        onSuccess: res => {
            if (res.data) {
                setDeviceExist('Device name already taken')
            }
            else {
                setDeviceExist('')
            }
        }
    })

    let debounced = useDebounce(data?.displayName, 500)

    useEffect(() => {
        if (debounced) {
            validateDeviceName()
        }
    }, [debounced])


    const obtainCoords = () => {
        setError(false);
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setData({ ...data, latitude, longitude })
            }, function (error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setError("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setError("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        setError("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        setError("An unknown error occurred.");
                        break;
                }
            });
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    }

    const getIp = async () => {
        let ip = await fetch('https://api.db-ip.com/v2/free/self');
        ip = await ip.json()
        if (ip?.ipAddress) {
            // setDeviceId(window.screen?.width + "-" + ip.ipAddress.replace(/\./g, '-'))
        }
    }

    let { mutate: updateDevice, isLoading: updating } = useUpdateDevice(() => dispatch(setDeviceInfo({ ...deviceInfo, ...data })))
    let { mutate: registerDevice, isLoading } = useRegisterDevice(() => { setLoading(true); localStorage.setItem("device-id", deviceId) })
    // setSuccess({ title: 'Setup Successful!' })
    let { mutate: handleState } = useUpdateDeviceState(() => {
        if (disabled) {
            setSuccess({
                title: "Enabled Successfully!"
            })
            setDisabled(!disabled);
            dispatch(setDeviceInfo({ ...deviceInfo, active: 1 }))
        } else {
            setSuccess({
                title: "Disabled Successfully!"
            })
            setDisabled(!disabled)
            dispatch(setDeviceInfo({ ...deviceInfo, active: 0 }))
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        registerDevice({
            companyId: company?.id,
            latitude: data.latitude,
            longitude: data.longitude,
            displayName: data.displayName,
            deviceId
        });
    }

    const updateDisplayName = () => {
        if (data?.displayName == deviceInfo?.displayName) {
            toast("Already up to date", { id: 'info' })
        }
        else {
            updateDevice({ displayName: data?.displayName, deviceId: deviceInfo?.deviceId })
        }
    }

    const generateID = () => {
        let id = scrambleWord(data.displayName)
        setDeviceId(scrambleWord('#$%$') + id + scrambleWord('%()-^'))
    }

    const { mutate: verifyQR } = useAuthorizeSetting(() => { setVerified(true) }, (data) => setPopUp({ type: 'failure', failureMessage: data }))
    const scanQR = (text) => {
        verifyQR({
            qrId: text,
            sectorId: deviceInfo?.sector?.id,
        })
    }

    return (
        <>
            <section className='flex flex-col w-full min-h-screen bg-gray overflow-hidden'>
                <Navbar />
                {
                    isSetUp
                        ?
                        <div className='px-5 lg:px-16 xl:px-20 flex-grow flex flex-col gap-4 relative py-12'>
                            <svg onClick={() => deviceInfo ? setIsSetUp(false) : history?.goBack()} width="87" height="60" className='absolute top-4 left-10 cursor-pointer' viewBox="0 0 87 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M82.5117 34.2227C84.7209 34.2227 86.5117 32.4318 86.5117 30.2227C86.5117 28.0135 84.7209 26.2227 82.5117 26.2227V34.2227ZM1.61827 27.3942C0.0561752 28.9563 0.0561752 31.489 1.61827 33.0511L27.0741 58.5069C28.6362 60.069 31.1689 60.069 32.731 58.5069C34.2931 56.9448 34.2931 54.4122 32.731 52.8501L10.1036 30.2227L32.731 7.59524C34.2931 6.03314 34.2931 3.50048 32.731 1.93839C31.1689 0.376287 28.6362 0.376287 27.0741 1.93839L1.61827 27.3942ZM82.5117 26.2227L4.4467 26.2227V34.2227L82.5117 34.2227V26.2227Z" fill="#FA8A22" />
                            </svg>
                            <div className='w-full max-w-md xl:max-w-lg mx-auto flex-grow'>
                                <div className=''>
                                    <h1 className='text-3xl font-medium'>Edit Display Name</h1>
                                    <div className='flex items-center gap-4 mt-2 relative'>
                                        <input type="text" value={data.displayName} onChange={(e) => { if (e.target.value?.length < 26) { setData({ ...data, displayName: e.target.value }); setDeviceExist('') } else { } }} className='py-2 px-3 text-xl bg-[#474747] rounded-xl w-full outline-none border-none flex-grow' />
                                        <button onClick={() => generateID()} disabled={deviceExist || data.displayName?.length < 3} className='bg-[#138188] py-1.5 px-5 rounded-lg absolute -right-36 bottom-1'>Generate ID</button>
                                    </div>
                                    <p className='text-xs text-red'>{deviceExist}</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='text-3xl font-medium'>Device ID</h1>
                                    <input type="text" value={deviceId} disabled className='py-2 px-3 text-xl bg-[#141414] rounded-xl w-full outline-none border-none mt-2 text-white' />
                                    <p className='font-thin italic my-1'>Enter ID in the sector this device will be associated with - in the branch Admin Portal.</p>
                                </div>
                                <div className='flex flex-col mt-5'>
                                    <div className='flex gap-3 items-center'>
                                        <h1 className='text-xl'>Device Latitude and Longitude</h1>
                                        {typeof error !== 'undefined' ? <div className='flex items-center gap-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='none' strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 ${error ? 'stroke-[#FF4B4B]' : 'stroke-[#0BCF89]'}`}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                            </svg>
                                            {
                                                error ? <span className='text-red text-sm'>Location needs to be turned on</span> : <span className='text-green text-sm'>Location is on</span>
                                            }
                                        </div> : ''}
                                    </div>
                                    <div className='flex gap-4 my-2'>
                                        <input type="text" readOnly placeholder='Latitude' value={data.latitude} className='cursor-default p-2 my-1 rounded-lg bg-[#474747] outline-none border-none flex-grow' />
                                        <input type="text" readOnly placeholder='Longitude' value={data.longitude} className='cursor-default p-2 my-1 rounded-lg bg-[#474747] outline-none border-none flex-grow' />
                                    </div>
                                    <button className='py-1.5 px-10 rounded-full bg-orange max-w-max mx-auto' onClick={() => obtainCoords()}>Obtain Values</button>
                                </div>
                            </div>
                            <div className='w-full flex items-center justify-between max-w-3xl mx-auto'>
                                <button onClick={() => deviceInfo ? setIsSetUp(false) : history.goBack()} className='py-2 px-6 min-w-[150px] rounded-full text-lg font-medium bg-red'>Cancel Set up</button>
                                <button disabled={!deviceId || data.displayName?.length < 3 || !data.longitude || !data.latitude || isLoading} onClick={handleSubmit} className='py-2 px-6 min-w-[150px] rounded-full text-lg font-medium bg-green'>Complete Set up</button>
                            </div>
                        </div>
                        :
                        verified
                            ?
                            <div className='px-5 lg:px-16 xl:px-20 flex-grow flex flex-col gap-4 relative'>
                                {deviceInfo ? <svg onClick={() => history.goBack()} width="87" height="60" className='absolute top-4 left-10 cursor-pointer' viewBox="0 0 87 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M82.5117 34.2227C84.7209 34.2227 86.5117 32.4318 86.5117 30.2227C86.5117 28.0135 84.7209 26.2227 82.5117 26.2227V34.2227ZM1.61827 27.3942C0.0561752 28.9563 0.0561752 31.489 1.61827 33.0511L27.0741 58.5069C28.6362 60.069 31.1689 60.069 32.731 58.5069C34.2931 56.9448 34.2931 54.4122 32.731 52.8501L10.1036 30.2227L32.731 7.59524C34.2931 6.03314 34.2931 3.50048 32.731 1.93839C31.1689 0.376287 28.6362 0.376287 27.0741 1.93839L1.61827 27.3942ZM82.5117 26.2227L4.4467 26.2227V34.2227L82.5117 34.2227V26.2227Z" fill="white" />
                                </svg> : <></>}
                                <div className='flex-grow flex flex-col items-center justify-center gap-4 -mt-20'>
                                    <h1 className='text-5xl font-medium'>Edit Display Name</h1>
                                    <input type="text" disabled={!deviceInfo} value={data.displayName} onChange={(e) => setData({ ...data, displayName: e.target.value })} className='py-2 px-3 text-xl bg-[#474747] rounded-xl w-full max-w-sm outline-none border-none' />
                                    <div className='flex justify-end w-full max-w-sm'>
                                        <button className='py-1 px-6 rounded-full bg-green' disabled={!deviceInfo || data?.displayName?.length < 3 || updating} onClick={updateDisplayName}>Submit</button>
                                    </div>
                                    {!deviceInfo?.sector ? <button className='py-2 px-6 rounded-full flex items-center justify-center gap-2 bg-orange text-3xl mt-6' onClick={() => setIsSetUp(true)}>
                                        <span>Set up Device</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                        </svg>
                                    </button>
                                        :
                                        <button className='py-2 px-6 rounded-full flex items-center justify-center gap-2 bg-red text-xl mt-6' onClick={() => setReset(true)}>
                                            <span>Reset Device</span>
                                        </button>
                                    }
                                    <button disabled={!deviceInfo} className={`py-2 px-4 rounded-xl flex flex-col items-center justify-center gap-2 absolute bottom-2 right-2 ${disabled ? 'bg-green' : 'bg-red'}`} onClick={() => {
                                        if (disabled) {
                                            handleState({ deviceId: deviceInfo?.deviceId, active: 1 })
                                        } else {
                                            setConfirmation({
                                                onClose: () => setConfirmation(false),
                                                title: 'This will disable only employee scanning',
                                                description: 'The scanner for entering the settings will still function',
                                                okText: 'Disable Scanning',
                                                onOk: () => { handleState({ deviceId: deviceInfo?.deviceId, active: 0 }); setConfirmation(false) }
                                            })
                                        }
                                    }}>
                                        <svg width="32" height="32" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.548 0.261719V12.9273H19.673V0.261719H16.548ZM9.51671 5.77422C6.24143 7.66626 3.68132 10.5854 2.23281 14.0795C0.784307 17.5737 0.528221 21.4479 1.50421 25.1023C2.48571 28.7558 4.64434 31.9838 7.64585 34.2865C10.6474 36.5892 14.3243 37.8381 18.1073 37.8398C21.8898 37.8374 25.5661 36.5882 28.5669 34.2856C31.5678 31.9829 33.726 28.7553 34.7073 25.1023C35.6839 21.4486 35.4287 17.5749 33.9814 14.0808C32.534 10.5867 29.9752 7.66719 26.7011 5.77422L25.1386 8.47734C27.8208 10.0239 29.917 12.4132 31.1014 15.2738C32.2859 18.1344 32.4923 21.3061 31.6886 24.2961C30.89 27.287 29.1254 29.9302 26.6692 31.8144C24.2131 33.6986 21.203 34.7183 18.1073 34.7148C15.0117 34.7183 12.0016 33.6986 9.54543 31.8144C7.08925 29.9302 5.32464 27.287 4.52609 24.2961C3.72236 21.3061 3.92877 18.1344 5.11325 15.2738C6.29772 12.4132 8.39392 10.0239 11.0761 8.47734L9.51359 5.77422H9.51671Z" fill="black" />
                                        </svg>
                                        <span className='text-black text-lg font-semibold'>{disabled ? 'Enable' : 'Disable'} Scanning</span>
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='px-5 py-4 lg:px-16 xl:px-20 flex-grow flex flex-col gap-4 relative'>
                                <div className='flex justify-between items-start'>
                                    <div className='flex items-center gap-3'>
                                        {deviceInfo ? <svg onClick={() => history.goBack()} width="87" height="60" className='cursor-pointer' viewBox="0 0 87 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M82.5117 34.2227C84.7209 34.2227 86.5117 32.4318 86.5117 30.2227C86.5117 28.0135 84.7209 26.2227 82.5117 26.2227V34.2227ZM1.61827 27.3942C0.0561752 28.9563 0.0561752 31.489 1.61827 33.0511L27.0741 58.5069C28.6362 60.069 31.1689 60.069 32.731 58.5069C34.2931 56.9448 34.2931 54.4122 32.731 52.8501L10.1036 30.2227L32.731 7.59524C34.2931 6.03314 34.2931 3.50048 32.731 1.93839C31.1689 0.376287 28.6362 0.376287 27.0741 1.93839L1.61827 27.3942ZM82.5117 26.2227L4.4467 26.2227V34.2227L82.5117 34.2227V26.2227Z" fill="white" />
                                        </svg> : <></>}
                                        <svg width="50" height="50" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M46.9587 48.9887C44.9207 46.2904 42.284 44.1022 39.2563 42.5965C36.2287 41.0907 32.8927 40.3086 29.5112 40.3116C26.1298 40.3086 22.7938 41.0907 19.7661 42.5965C16.7384 44.1022 14.1017 46.2904 12.0637 48.9887M46.9587 48.9887C50.9356 45.4514 53.7403 40.7887 55.0063 35.619C56.2724 30.4493 55.937 25.0169 54.0447 20.0422C52.1524 15.0675 48.7925 10.7856 44.4107 7.76426C40.0289 4.74294 34.8322 3.125 29.5098 3.125C24.1873 3.125 18.9906 4.74294 14.6088 7.76426C10.227 10.7856 6.86719 15.0675 4.97487 20.0422C3.08255 25.0169 2.74717 30.4493 4.0132 35.619C5.27923 40.7887 8.08685 45.4514 12.0637 48.9887M46.9587 48.9887C42.158 53.2709 35.9443 55.6333 29.5112 55.6241C23.0771 55.634 16.8653 53.2716 12.0637 48.9887M38.2612 22.8116C38.2612 25.1323 37.3394 27.3579 35.6984 28.9988C34.0575 30.6398 31.8319 31.5616 29.5112 31.5616C27.1906 31.5616 24.965 30.6398 23.324 28.9988C21.6831 27.3579 20.7612 25.1323 20.7612 22.8116C20.7612 20.491 21.6831 18.2654 23.324 16.6245C24.965 14.9835 27.1906 14.0616 29.5112 14.0616C31.8319 14.0616 34.0575 14.9835 35.6984 16.6245C37.3394 18.2654 38.2612 20.491 38.2612 22.8116Z" stroke="#FF9A3E" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span className='text-3xl font-medium text-[#FF9A3E] '>Scanner Settings</span>
                                    </div>
                                    <div className='flex flex-col gap-3 text-right'>
                                        <h1 className='text-2xl font-medium'>{deviceInfo?.displayName}</h1>
                                        <div className='flex items-center justify-end gap-4'>
                                            <h1 className='text-lg'>{moment(time).format("hh:mm A")}</h1>
                                            <svg width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.625" cy="8.12109" r="7.5" fill="white" />
                                            </svg>
                                            <h1 className='text-lg'>{moment(time).format("DD/MM/YY")}</h1>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className='bg-black py-2 w-full rounded-full' onClick={() => scanQR('35d90040-5dbd-11ee-842d-67f0900d3919')}>VERIFY </button> */}

                                <div className='flex flex-col items-center justify-center flex-grow'>
                                    <div className='w-full max-w-md rounded-lg bg-1'>
                                        <QrReader
                                            constraints={{
                                                facingMode: 'environment'
                                            }}
                                            scanDelay={5000}
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    console.log(result?.text)
                                                    scanQR(result.text)
                                                }
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <h1 className='text-3xl mt-4'>Scan the Generated QR code</h1>
                                </div>
                            </div>
                }


                {confirmation ? <ConfirmationModal onClose={confirmation?.onClose} title={confirmation?.title} description={confirmation?.description} onOk={confirmation?.onOk} okText={confirmation?.okText} /> : <></>}
                {success ? <SuccessModal onClose={() => setSuccess(false)} title={success?.title} /> : <></>}
                {loading ? <Loading onClose={() => setLoading(false)} /> : <></>}
                {reset ? <ResetDevice onClose={() => setReset(false)} onOk={resetDevice} /> : <></>}
                {popUp ? <PopUp active={'Authorization'} popUpType={popUp?.type} assistedBy={popUp?.assistedBy} userName={popUp?.userName} failureMessage={popUp?.failureMessage} onClose={() => setPopUp(false)} /> : <></>}

            </section>
        </>
    )
}

export default DeviceSetting


const SuccessModal = ({ onClose, title }) => {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={onClose}></div>
            <div className='z-10 relative w-full max-w-3xl rounded-xl bg-gray min-h-[500px] flex items-center justify-center gap-10 pt-16 opacAnimation'>
                <h1 className='text-5xl text-green font-medium absolute top-10 left-1/2 transform -translate-x-1/2 min-w-max'>{title}</h1>
                <svg width="158" height="201" viewBox="0 0 158 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.987 153.541C79.9994 153.541 99.4653 134.075 99.4653 110.062C99.4653 86.0499 79.9994 66.584 55.987 66.584C31.9747 66.584 12.5088 86.0499 12.5088 110.062C12.5088 134.075 31.9747 153.541 55.987 153.541Z" fill="#12A579" />
                    <path d="M70.2283 189.683C94.2406 189.683 113.707 170.217 113.707 146.205C113.707 122.192 94.2406 102.727 70.2283 102.727C46.2159 102.727 26.75 122.192 26.75 146.205C26.75 170.217 46.2159 189.683 70.2283 189.683Z" fill="#12A579" />
                    <path d="M97.3343 164.411C115.344 164.411 129.943 149.811 129.943 131.802C129.943 113.793 115.344 99.1934 97.3343 99.1934C79.325 99.1934 64.7256 113.793 64.7256 131.802C64.7256 149.811 79.325 164.411 97.3343 164.411Z" fill="#12A579" />
                    <path d="M153.813 99.1935C153.813 153.221 119.748 197.02 77.7265 197.02C35.7049 197.02 1.63965 153.221 1.63965 99.1935C1.63965 45.1652 35.7049 1.36719 77.7265 1.36719C119.748 1.36719 153.813 45.1652 153.813 99.1935ZM20.3934 99.1935C20.3934 139.904 46.0626 172.907 77.7265 172.907C109.391 172.907 135.06 139.904 135.06 99.1935C135.06 58.4822 109.391 25.4792 77.7265 25.4792C46.0626 25.4792 20.3934 58.4822 20.3934 99.1935Z" fill="#0BCF89" />
                    <path d="M48.0586 126.725L61.3919 140.059L81.3919 110.059" stroke="#242424" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <div>
                    <h1 className='text-3xl font-medium'>Redirecting to home page in</h1>
                    <div className='flex items-center justify-center gap-2 text-3xl mt-6'>
                        <svg width="32" height="32" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.0937 7.79297V20.293H30.4687M39.8437 20.293C39.8437 22.7553 39.3588 25.1934 38.4165 27.4683C37.4742 29.7431 36.0931 31.8101 34.352 33.5512C32.6109 35.2923 30.5439 36.6734 28.2691 37.6157C25.9942 38.558 23.556 39.043 21.0937 39.043C18.6315 39.043 16.1933 38.558 13.9184 37.6157C11.6436 36.6734 9.5766 35.2923 7.8355 33.5512C6.0944 31.8101 4.71328 29.7431 3.77101 27.4683C2.82873 25.1934 2.34375 22.7553 2.34375 20.293C2.34375 15.3202 4.31919 10.551 7.8355 7.03472C11.3518 3.51841 16.1209 1.54297 21.0937 1.54297C26.0666 1.54297 30.8357 3.51841 34.352 7.03472C37.8683 10.551 39.8437 15.3202 39.8437 20.293Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>5 Sec</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

const Loading = ({ onClose }) => {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={onClose}></div>
            <div className='z-10 relative w-full max-w-3xl rounded-xl bg-gray min-h-[500px] flex flex-col items-center justify-center gap-10 opacAnimation'>
                <div className='border-[20px] border-orange-400 border-b-transparent animate-spin w-28 h-28 rounded-full'></div>
                <h1 className='text-4xl font-medium leading-snug text-center'>Waiting for Branch<br />Admin Confirmation</h1>
                <button className='bg-orange py-1.5 px-8 rounded-full text-lg outline-none border-none' onClick={onClose}>Show Details</button>
            </div>
        </section>
    )
}

const ResetDevice = ({ onClose, onOk }) => {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={onClose}></div>
            <div className='z-10 relative w-full max-w-2xl rounded-xl bg-gray min-h-[450px] flex flex-col items-center justify-center gap-4 p-10 opacAnimation'>
                <h1 className='text-5xl font-medium text-center my-6'>Reset Device Set up</h1>
                <p className='text-xl text-center max-w-xl text-red-200'>This action will disable the scanner and remove device from admin Portal.</p>
                <p className='text-lg text-center max-w-xl text-red-200 font-extralight'>This device can be set up again. But will contain a new Device id</p>
                <div className='w-full flex justify-between max-w-xl mt-auto'>
                    <button className='py-2 min-w-[120px] px-6 rounded-xl bg-green font-medium' onClick={onClose}>Cancel</button>
                    <button className='py-2 min-w-[120px] px-6 rounded-xl bg-red text-black font-medium' onClick={onOk}>Reset Device</button>
                </div>
            </div>

        </section>
    )
}