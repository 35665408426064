import React from 'react'
import Logo from '../../assets/logo.svg'
import Rain from '../../assets/rain.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function NotFound() {
    const history = useHistory()
    return (
        <div className='w-full h-screen flex flex-col overflow-hidden bg-[#400D35]'>
            <nav className='bg-[#1F4B4B] py-3 px-5 flex items-center justify-between'>
                <div className='flex items-end cursor-pointer' onClick={() => history.push("/")}>
                    <img src={Logo} alt="" />
                    <h1 className='text-3xl font-medium'>Aqqess</h1>
                </div>
            </nav>
            <div className='flex-grow p-10 flex items-center justify-around'>
                <img src={Rain} alt="" />
                <div className='w-full max-w-xl text-right space-y-8'>
                    <h1 className='text-7xl font-bold'>Lost Aqqess</h1>
                    <button onClick={() => history.push('/')} className='py-1.5 px-6 rounded-xl bg-[#FF63DD] text-lg'>Return to home</button>
                </div>
            </div>
        </div>
    )
}

export default NotFound