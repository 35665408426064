import { Switch, Route } from 'react-router-dom';
import DeviceHome from './components/Device/DeviceHome';
import DeviceSetting from './components/Device/DeviceSetting';
import { useInitialLoad } from './hooks';
import Loader from './components/Reusable/Loader';
import DeviceRoute from './DeviceRoute';
import NotFound from './components/Reusable/NotFound';

function Routes() {

    let { isLoading } = useInitialLoad()

    if (isLoading) {
        return <Loader />
    }

    return (
        <Switch>
            <DeviceRoute exact path="/" component={DeviceHome} />
            <DeviceRoute exact path="/setting" component={DeviceSetting} />
            <Route exact path="*" component={NotFound} />
        </Switch>
    );
}

export default Routes;
