import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    deviceInfo: null,
    company: null,
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setDeviceInfo: (state, action) => {
            state.deviceInfo = action.payload || null
        },
        setCompany: (state, action) => {
            state.company = action.payload || null
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDeviceInfo, setCompany, setDeviceSectors } = deviceSlice.actions

export const selectDeviceInfo = (state) => state.device.deviceInfo
export const selectCompany = (state) => state.device.company

export default deviceSlice.reducer