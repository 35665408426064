import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { setCompany, setDeviceInfo } from "../slices/deviceSlice"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { DeviceService } from "../services/device.service"

export const useInitialLoad = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [ipAddress, setIpAddress] = useState('')

    let loc = window.location.hostname?.split(".")
    let subdomain = loc[0]

    let { isLoading: loading, data: companyData, refetch: validateCompany } = useQuery('validateCompany', () => DeviceService.getCompany(subdomain), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => {
            if (res?.success && !res?.data) {
                if (loc[1] == 'device')
                    history.push('/404')
                // if (subdomain == 'aqqess') {
                // } else
                //     if (subdomain == 'aqqess') {
                //     } else
                //         if (subdomain == 'localhost') {
                //         } else
            }
        },
        onError: (e) => console.log(e)
    })

    useEffect(() => {
        if (loc[1] == 'device') {
            validateCompany()
        }
        // if (subdomain !== 'aqqess' || subdomain !== 'localhost' || subdomain !== 'aqqess') {
        // }
    }, [subdomain])


    let { isLoading, data, isError, error, isFetching, refetch: validateToken } = useQuery('validateToken', () => DeviceService.auth(), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    const getIp = async () => {
        let ip = await fetch('https://api.db-ip.com/v2/free/self');
        ip = await ip.json()
        if (ip?.ipAddress) {
            setIpAddress(window.screen?.width + "-" + ip.ipAddress.replace(/\./g, '-'))
        }
    }

    useEffect(() => { validateToken() }, [])
    // useEffect(() => { if (ipAddress) { validateToken() } }, [ipAddress])

    useEffect(() => {
        if (companyData?.data) {
            // validateToken();
            dispatch(setCompany(companyData?.data));
        }
        getIp()
    }, [companyData])

    useEffect(() => {
        if (!isLoading) {
            if (data?.accessToken) {
                localStorage.setItem("device_aqqess_token", data.accessToken)
            }
            if(data?.success){
                if (data?.data) {
                    dispatch(setDeviceInfo(data?.data));
                }
                else{
                    localStorage.removeItem('device-id');
                    localStorage.removeItem('device_aqqess_token');
                }
            }
            history.push('/')
        }
    }, [data])

    return { isLoading: isLoading || loading, isError, error, isFetching }
}

export const useUpdateDevice = (callback) => {
    return useMutation(DeviceService.updateDevice, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast.success("Updated Successfully!", { id: 'success' })
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useRegisterDevice = (callback) => {
    const dispatch = useDispatch();
    return useMutation(DeviceService.registerDevice, {
        onSuccess: (response) => {
            if (response?.success === true) {
                dispatch(setDeviceInfo(response?.data))
                if (response?.accessToken) {
                    localStorage.setItem("device_aqqess_token", response.accessToken)
                }
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdateDeviceState = (callback) => {
    return useMutation(DeviceService.updateDevice, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useVerifyQr = (callback, errorCallback) => {
    return useMutation(DeviceService.verifyQR, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                errorCallback(response?.message)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAuthorizeSetting = (callback, errorCallback) => {
    return useMutation(DeviceService.authorizeSetting, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                errorCallback(response?.message)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

